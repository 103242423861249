exports.components = {
  "component---src-components-page-tsx-content-file-path-src-content-blog-ai-hub-de-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/blog/ai-hub.de.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-blog-ai-hub-de-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-blog-ai-hub-en-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/blog/ai-hub.en.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-blog-ai-hub-en-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-blog-datatalks-club-de-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/blog/datatalks-club.de.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-blog-datatalks-club-de-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-blog-datatalks-club-en-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/blog/datatalks-club.en.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-blog-datatalks-club-en-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-blog-flower-summit-de-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/blog/flower-summit.de.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-blog-flower-summit-de-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-blog-flower-summit-en-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/blog/flower-summit.en.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-blog-flower-summit-en-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-blog-index-de-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/blog/index.de.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-blog-index-de-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-blog-index-en-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/blog/index.en.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-blog-index-en-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-blog-katulu-fl-suite-open-source-de-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/blog/katulu-fl-suite-open-source.de.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-blog-katulu-fl-suite-open-source-de-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-blog-katulu-fl-suite-open-source-en-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/blog/katulu-fl-suite-open-source.en.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-blog-katulu-fl-suite-open-source-en-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-blog-ki-industrie-de-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/blog/ki-industrie.de.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-blog-ki-industrie-de-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-blog-ki-industrie-en-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/blog/ki-industrie.en.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-blog-ki-industrie-en-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-company-about-de-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/company/about.de.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-company-about-de-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-company-about-en-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/company/about.en.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-company-about-en-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-company-career-de-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/company/career.de.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-company-career-de-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-company-career-en-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/company/career.en.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-company-career-en-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-glossary-de-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/glossary.de.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-glossary-de-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-glossary-en-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/glossary.en.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-glossary-en-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-imprint-de-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/imprint.de.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-imprint-de-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-imprint-en-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/imprint.en.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-imprint-en-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-index-de-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/index.de.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-index-de-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-index-en-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/index.en.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-index-en-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-opensource-en-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/opensource.en.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-opensource-en-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-platform-data-catalog-de-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/platform/data-catalog.de.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-platform-data-catalog-de-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-platform-data-catalog-en-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/platform/data-catalog.en.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-platform-data-catalog-en-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-platform-data-governance-de-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/platform/data-governance.de.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-platform-data-governance-de-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-platform-data-governance-en-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/platform/data-governance.en.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-platform-data-governance-en-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-platform-data-onboarding-de-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/platform/data-onboarding.de.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-platform-data-onboarding-de-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-platform-data-onboarding-en-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/platform/data-onboarding.en.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-platform-data-onboarding-en-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-platform-data-science-de-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/platform/data-science.de.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-platform-data-science-de-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-platform-data-science-en-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/platform/data-science.en.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-platform-data-science-en-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-platform-federated-learning-de-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/platform/federated-learning.de.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-platform-federated-learning-de-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-platform-federated-learning-en-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/platform/federated-learning.en.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-platform-federated-learning-en-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-platform-overview-de-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/platform/overview.de.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-platform-overview-de-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-platform-overview-en-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/platform/overview.en.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-platform-overview-en-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-platform-pipelines-de-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/platform/pipelines.de.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-platform-pipelines-de-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-platform-pipelines-en-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/platform/pipelines.en.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-platform-pipelines-en-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-platform-pricing-de-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/platform/pricing.de.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-platform-pricing-de-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-platform-pricing-en-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/platform/pricing.en.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-platform-pricing-en-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-platform-services-de-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/platform/services.de.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-platform-services-de-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-platform-services-en-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/platform/services.en.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-platform-services-en-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-privacy-policy-de-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/privacy-policy.de.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-privacy-policy-de-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-privacy-policy-en-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/privacy-policy.en.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-privacy-policy-en-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-solutions-briefs-digital-twin-metallurgy-de-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/solutions/briefs/digital-twin-metallurgy.de.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-solutions-briefs-digital-twin-metallurgy-de-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-solutions-briefs-digital-twin-metallurgy-en-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/solutions/briefs/digital-twin-metallurgy.en.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-solutions-briefs-digital-twin-metallurgy-en-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-solutions-briefs-factory-2-factory-de-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/solutions/briefs/factory2factory.de.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-solutions-briefs-factory-2-factory-de-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-solutions-briefs-factory-2-factory-en-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/solutions/briefs/factory2factory.en.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-solutions-briefs-factory-2-factory-en-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-solutions-briefs-oee-plastic-rubber-de-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/solutions/briefs/oee-plastic-rubber.de.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-solutions-briefs-oee-plastic-rubber-de-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-solutions-briefs-oee-plastic-rubber-en-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/solutions/briefs/oee-plastic-rubber.en.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-solutions-briefs-oee-plastic-rubber-en-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-solutions-briefs-oee-toolwear-de-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/solutions/briefs/oee-toolwear.de.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-solutions-briefs-oee-toolwear-de-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-solutions-briefs-oee-toolwear-en-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/solutions/briefs/oee-toolwear.en.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-solutions-briefs-oee-toolwear-en-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-solutions-briefs-predictive-maintenance-pumps-compressors-de-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/solutions/briefs/predictive-maintenance-pumps-compressors.de.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-solutions-briefs-predictive-maintenance-pumps-compressors-de-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-solutions-briefs-predictive-maintenance-pumps-compressors-en-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/solutions/briefs/predictive-maintenance-pumps-compressors.en.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-solutions-briefs-predictive-maintenance-pumps-compressors-en-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-solutions-digital-twin-de-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/solutions/digital-twin.de.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-solutions-digital-twin-de-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-solutions-digital-twin-en-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/solutions/digital-twin.en.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-solutions-digital-twin-en-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-solutions-electronics-manufacturing-de-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/solutions/electronics-manufacturing.de.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-solutions-electronics-manufacturing-de-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-solutions-electronics-manufacturing-en-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/solutions/electronics-manufacturing.en.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-solutions-electronics-manufacturing-en-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-solutions-index-de-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/solutions/index.de.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-solutions-index-de-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-solutions-index-en-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/solutions/index.en.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-solutions-index-en-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-solutions-inline-quality-monitoring-de-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/solutions/inline-quality-monitoring.de.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-solutions-inline-quality-monitoring-de-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-solutions-inline-quality-monitoring-en-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/solutions/inline-quality-monitoring.en.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-solutions-inline-quality-monitoring-en-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-solutions-machine-tools-de-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/solutions/machine-tools.de.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-solutions-machine-tools-de-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-solutions-machine-tools-en-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/solutions/machine-tools.en.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-solutions-machine-tools-en-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-solutions-metallurgy-de-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/solutions/metallurgy.de.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-solutions-metallurgy-de-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-solutions-metallurgy-en-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/solutions/metallurgy.en.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-solutions-metallurgy-en-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-solutions-oee-de-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/solutions/oee.de.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-solutions-oee-de-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-solutions-oee-en-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/solutions/oee.en.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-solutions-oee-en-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-solutions-plastics-de-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/solutions/plastics.de.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-solutions-plastics-de-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-solutions-plastics-en-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/solutions/plastics.en.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-solutions-plastics-en-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-solutions-predictive-maintenance-de-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/solutions/predictive-maintenance.de.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-solutions-predictive-maintenance-de-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-solutions-predictive-maintenance-en-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/solutions/predictive-maintenance.en.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-solutions-predictive-maintenance-en-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-solutions-process-industry-de-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/solutions/process-industry.de.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-solutions-process-industry-de-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-solutions-process-industry-en-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/solutions/process-industry.en.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-solutions-process-industry-en-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-solutions-pumps-compressors-de-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/solutions/pumps-compressors.de.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-solutions-pumps-compressors-de-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-solutions-pumps-compressors-en-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/solutions/pumps-compressors.en.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-solutions-pumps-compressors-en-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-solutions-value-chain-de-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/solutions/value-chain.de.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-solutions-value-chain-de-mdx" */),
  "component---src-components-page-tsx-content-file-path-src-content-solutions-value-chain-en-mdx": () => import("./../../../src/components/Page.tsx?__contentFilePath=/workspace/src/content/solutions/value-chain.en.mdx" /* webpackChunkName: "component---src-components-page-tsx-content-file-path-src-content-solutions-value-chain-en-mdx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */)
}

