module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-remark-images-virtual-a08d1d1d2b/0/cache/gatsby-remark-images-npm-7.8.0-28c3fcd5fd-db5df648dd.zip/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-remark-images-virtual-a08d1d1d2b/0/cache/gatsby-remark-images-npm-7.8.0-28c3fcd5fd-db5df648dd.zip/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1280},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-dcf990260a/0/cache/gatsby-plugin-manifest-npm-5.8.0-26e7b141df-30f4ec20a4.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/assets/icon.png","background_color":"#FFFFFF","theme_color":"#FFFFFF","name":"Katulu","short_name":"Katulu","description":"Solutions for an independent industry.","lang":"en-US","start_url":"/en/","localize":[{"name":"Katulu","short_name":"Katulu","description":"Lösungen für eine unabhängige Industrie.","start_url":"/de/","lang":"de-DE"},{"name":"Katulu","short_name":"Katulu","description":"Solutions for an independent industry.","start_url":"/en/","lang":"en-US"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8dd70a7ef18b10419878c8b632fb2b84"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-styled-components-virtual-e48e5fb6a2/0/cache/gatsby-plugin-styled-components-npm-6.8.0-1fb2364e47-9d8d72cb80.zip/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"minify":true,"transpileTemplateLiterals":true,"pure":true,"displayName":true,"fileName":true,"namespace":"","topLevelImportPaths":[],"disableVendorPrefixes":false},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-0c349587ed/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
